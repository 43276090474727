@use 'colors';
@use 'shapes';
@use 'animations';

.Home {
    display: flex;
    contain: strict;
    min-height: 100vh;
    width: 100%;
    color: white;

    .BackgroundImg {
        max-width: 40vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: colors.$green-800;
        filter: brightness(0.5);
    }
    .HomeCircle {
        @extend .circle;
        width: calc(20vw + 1vmin);
        height: calc(20vw + 1vmin);
        max-width: 300px;
        max-height: 300px;
        position: relative;
        left: calc(-30vw + 1vmin);
        top: 0;
        flex-direction: column;
        justify-content: center;
        background-color: colors.$green-800;
        font-size: calc(10px + 2vmin);
        text-align: center;
    }
    .Bio {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-left: 10%;
        padding-right: 10%;
        font-size: calc(10px + 2vmin);
        background-color: colors.$green-800;
        filter: drop-shadow(-10px 4px 40px black);
    }
    .BioContent {
        overflow-y: auto;
    }
    .Title {
        align-self: flex-start;
        margin-bottom: revert;
    }
    .bio-slide-in {
        @extend .right-to-left-enter;
    }
    .bio-slide-in.bio-slide-in-active {
        @extend .right-to-left-enter-active;
    }
    .bio-slide-out {
        @extend .right-to-left-exit;
    }
    .bio-slide-out.bio-slide-out-active {
        @extend .right-to-left-exit-active;
    }
}