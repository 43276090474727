.square {
    display: flex;
    width: 100px;
    height: 100px;
  }
  
  .circle {
    display: flex;
    flex: 0 0 auto;
    width: 100px;
    height: 100px;
    border-radius: 50% 0 0 50%;
  }
  
  .text {
    margin: auto;
  }